.banner {
  height: 249px;
  width: 1728px;
}

.banner .overlap-group {
  height: 249px;
  position: relative;
}

.banner .image {
  height: 249px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 1728px;
}

.banner .rectangle {
  background-color: #28282863;
  height: 249px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1728px;
}

.banner .text-wrapper-3 {
  color: #ffffff;
  font-family: "NanumBarunGothic-Regular", Helvetica;
  font-size: 38px;
  font-weight: 400;
  left: 47px;
  letter-spacing: 0;
  line-height: 52.2px;
  position: absolute;
  top: 61px;
}

.banner .text-wrapper-4 {
  color: #ffffff;
  font-family: "NanumBarunGothic-Regular", Helvetica;
  font-size: 22px;
  font-weight: 400;
  left: 47px;
  letter-spacing: 0;
  line-height: 30.2px;
  position: absolute;
  top: 193px;
  white-space: nowrap;
}
