.map {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.map .div-2 {
  background-color: #ffffff;
  height: 1662px;
  position: relative;
  width: 1728px;
}

.map .upper-bar {
  background-color: #ffffff;
  height: 138px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1728px;
}

.map .under-bar-instance {
  left: 0 !important;
  position: absolute !important;
  top: 1384px !important;
}

.map .text-wrapper-9 {
  color: #fe0000;
  font-family: "NanumBarunGothic-Regular", Helvetica;
  font-size: 38px;
  font-weight: 400;
  left: 792px;
  letter-spacing: 0;
  line-height: 52.2px;
  position: absolute;
  text-align: center;
  top: 432px;
  white-space: nowrap;
}

.map .banner-instance {
  left: 0 !important;
  position: absolute !important;
  top: 138px !important;
}

.map .overlap {
  height: 61px;
  left: 628px;
  position: absolute;
  top: 526px;
  width: 475px;
}

.map .group {
  height: 61px;
  left: 0;
  position: absolute;
  top: 0;
  width: 238px;
}

.map .div-wrapper {
  /* background-color: #2f2f2f; */
  height: 61px;
  position: relative;
  width: 236px;
}

.map .text-wrapper-10 {
  color: #ffffff;
  font-family: "NanumBarunGothic-Regular", Helvetica;
  font-size: 25px;
  font-weight: 400;
  left: 17px;
  letter-spacing: 0;
  line-height: 34.3px;
  position: absolute;
  text-align: center;
  top: 14px;
  white-space: nowrap;
}

.map .overlap-wrapper {
  height: 61px;
  left: 237px;
  position: absolute;
  top: 0;
  width: 238px;
}

.map .overlap-2 {
  /* background-color: #d9d9d9; */
  height: 61px;
  position: relative;
  width: 236px;
}

.map .text-wrapper-11 {
  color: #000000;
  font-family: "NanumBarunGothic-Regular", Helvetica;
  font-size: 25px;
  font-weight: 400;
  left: 17px;
  letter-spacing: 0;
  line-height: 34.3px;
  position: absolute;
  text-align: center;
  top: 14px;
  white-space: nowrap;
}

.map .img {
  height: 602px;
  left: 367px;
  object-fit: cover;
  position: absolute;
  top: 628px;
  width: 995px;
}

.map .flexcontainer-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 89px;
  left: 527px;
  position: absolute;
  top: 1257px;
  width: 633px;
}

.map .text {
  align-self: stretch;
  color: #000000;
  font-family: "NanumBarunGothic-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
}

.map .text-wrapper-12 {
  color: #000000;
  font-family: "NanumBarunGothic-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
}
