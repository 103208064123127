.qna-screen {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.qna-screen .qna-2 {
  background-color: #ffffff;
  height: 1554px;
  position: relative;
  width: 1728px;
}

.qna-screen .upper-bar {
  background-color: #ffffff;
  height: 138px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1728px;
}

.qna-screen .under-bar-instance {
  left: 0 !important;
  position: absolute !important;
  top: 1276px !important;
}

.qna-screen .text-wrapper-15 {
  color: #fe0000;
  font-family: "NanumBarunGothic-Regular", Helvetica;
  font-size: 38px;
  font-weight: 400;
  left: 737px;
  letter-spacing: 0;
  line-height: 52.2px;
  position: absolute;
  text-align: center;
  top: 432px;
  white-space: nowrap;
}

.qna-screen .text-wrapper-16 {
  color: #000000;
  font-family: "NanumBarunGothic-Regular", Helvetica;
  font-size: 25px;
  font-weight: 400;
  left: 576px;
  letter-spacing: 0;
  line-height: 34.3px;
  position: absolute;
  text-align: center;
  top: 484px;
  white-space: nowrap;
}

.qna-screen .banner-instance {
  left: 0 !important;
  position: absolute !important;
  top: 138px !important;
}

.qna-screen .frame-6 {
  align-items: flex-start;
  display: inline-flex;
  left: 619px;
  position: absolute;
  top: 1222px;
}

.qna-screen .text-wrapper-17 {
  color: #000000;
  font-family: "NanumBarunGothic-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 29px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 99px;
}

.qna-screen .text-wrapper-18 {
  color: #000000;
  font-family: "NanumBarunGothic-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 29px;
  letter-spacing: 0;
  line-height: normal;
  margin-left: -1px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 99px;
}

.qna-screen .group {
  height: 50px;
  left: 1486px;
  position: absolute;
  top: 469px;
  width: 113px;
}

.qna-screen .overlap {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #000000;
  height: 50px;
  position: relative;
  width: 111px;
}

.qna-screen .text-wrapper-19 {
  color: #000000;
  font-family: "NanumBarunGothic-Regular", Helvetica;
  font-size: 23px;
  font-weight: 400;
  left: 24px;
  letter-spacing: 0;
  line-height: 35.5px;
  position: absolute;
  text-align: center;
  top: 8px;
  white-space: nowrap;
}

.qna-screen .frame-7 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  left: 121px;
  position: absolute;
  top: 539px;
}

.qna-screen .qna-instance {
  border-bottom-width: 2px !important;
  border-color: #fe0000 !important;
  border-top-style: solid !important;
  border-top-width: 2px !important;
}

.qna-screen .design-component-instance-node {
  font-family: "NanumBarunGothic-Bold", Helvetica !important;
  font-weight: 700 !important;
  left: 7px !important;
  top: 11px !important;
}

.qna-screen .qna-3 {
  font-family: "NanumBarunGothic-Bold", Helvetica !important;
  font-weight: 700 !important;
  left: 50px !important;
  top: 11px !important;
}

.qna-screen .qna-4 {
  font-family: "NanumBarunGothic-Bold", Helvetica !important;
  font-weight: 700 !important;
  left: 703px !important;
  top: 11px !important;
}

.qna-screen .qna-5 {
  font-family: "NanumBarunGothic-Bold", Helvetica !important;
  font-weight: 700 !important;
  left: 854px !important;
  top: 11px !important;
}

.qna-screen .qna-6 {
  font-family: "NanumBarunGothic-Bold", Helvetica !important;
  font-weight: 700 !important;
  left: 1037px !important;
  top: 11px !important;
}

.qna-screen .qna-7 {
  font-family: "NanumBarunGothic-Bold", Helvetica !important;
  font-weight: 700 !important;
  left: 1280px !important;
  top: 11px !important;
}

.qna-screen .frame-8 {
  align-items: flex-start;
  display: inline-flex;
  gap: 7px;
  left: 618px;
  position: absolute;
  top: 1152px;
}

.qna-screen .overlap-group-wrapper {
  height: 45px;
  position: relative;
  width: 128px;
}

.qna-screen .div-wrapper {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #000000;
  height: 45px;
  position: relative;
  width: 126px;
}

.qna-screen .text-wrapper-20 {
  color: #000000;
  font-family: "NanumBarunGothic-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 6px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 10px;
  width: 119px;
}

.qna-screen .rectangle-2 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #000000;
  height: 45px;
  position: relative;
  width: 262px;
}

.qna-screen .overlap-wrapper {
  height: 45px;
  margin-right: -2px;
  position: relative;
  width: 91px;
}

.qna-screen .overlap-2 {
  background-color: #2f2f2f;
  height: 45px;
  position: relative;
  width: 89px;
}

.qna-screen .text-wrapper-21 {
  color: #ffffff;
  font-family: "NanumBarunGothic-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 36px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 10px;
  width: 89px;
}

.qna-screen .qna-list.main {
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-top-style: solid;
  border-top-width: 2px;
  border-color: #FE0000;
}
