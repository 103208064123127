.upper-menu {
  align-items: flex-start;
  background-color: #ffffff;
  display: inline-flex;
  gap: 10px;
  padding: 7px 23px;
  position: relative;
}

.upper-menu .text-wrapper {
  color: #000000;
  font-family: "Inter-Regular", Helvetica;
  font-size: 23px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.upper-menu.active .text-wrapper {
  color: #fe0000;
  font-weight: 700;
}
