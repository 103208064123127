.login {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.login .div-2 {
  background-color: #ffffff;
  height: 1073px;
  position: relative;
  width: 1728px;
}

.login .upper-bar {
  background-color: #ffffff;
  height: 138px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1728px;
}

.login .text-wrapper-5 {
  color: #fe0000;
  font-family: "NanumBarunGothic-Regular", Helvetica;
  font-size: 38px;
  font-weight: 400;
  left: 813px;
  letter-spacing: 0;
  line-height: 52.2px;
  position: absolute;
  text-align: center;
  top: 432px;
  white-space: nowrap;
}

.login .banner-instance {
  left: 0 !important;
  position: absolute !important;
  top: 138px !important;
}

.login .input-id {
  background-color: #f7f7f7;
  height: 54px;
  left: 696px;
  position: absolute;
  top: 537px;
  width: 330px;
  font-family: "NanumBarunGothic-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  border: none;
  padding: 10px;
}

.login .input-pw {
  background-color: #f7f7f7;
  height: 54px;
  left: 696px;
  position: absolute;
  top: 608px;
  width: 329px;
  font-family: "NanumBarunGothic-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  border: none;
  padding: 10px;
}

.login .text-wrapper-6 {
  color: #000000;
  font-family: "NanumBarunGothic-Regular", Helvetica;
  font-size: 25px;
  font-weight: 400;
  left: 585px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 548px;
  white-space: nowrap;
}

.login .text-wrapper-7 {
  color: #000000;
  font-family: "NanumBarunGothic-Regular", Helvetica;
  font-size: 25px;
  font-weight: 400;
  left: 585px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 619px;
  white-space: nowrap;
}

.login .overlap {
  height: 126px;
  left: 1038px;
  position: absolute;
  top: 536px;
  width: 107px;
  cursor: pointer;
}

.login .rectangle-4 {
  background-color: #fe0000;
  height: 125px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 107px;
}

.login .text-wrapper-8 {
  color: #ffffff;
  font-family: "NanumBarunGothic-Regular", Helvetica;
  font-size: 25px;
  font-weight: 400;
  height: 125px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 50px;
  width: 107px;
}

.login .text-wrapper-9 {
  color: #000000;
  font-family: "NanumBarunGothic-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  height: 23px;
  left: 581px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 697px;
  white-space: nowrap;
  cursor: pointer;
}

.login .text-wrapper-10 {
  color: #000000;
  font-family: "NanumBarunGothic-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  height: 23px;
  left: 716px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 697px;
  white-space: nowrap;
  cursor: pointer;
}

.login .text-wrapper-11 {
  color: #000000;
  font-family: "NanumBarunGothic-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 23px;
  left: 691px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 697px;
  white-space: nowrap;
}

.login .text-wrapper-12 {
  color: #000000;
  font-family: "NanumBarunGothic-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 23px;
  left: 844px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 697px;
  white-space: nowrap;
}

.login .p {
  color: transparent;
  font-family: "NanumBarunGothic-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 23px;
  left: 869px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 697px;
  white-space: nowrap;
}

.login .text-wrapper-13 {
  color: #060606;
}

.login .text-wrapper-14 {
  color: #000000;
}

.login .text-wrapper-15 {
  color: #000000;
  font-family: "NanumBarunGothic-Bold", Helvetica;
  font-weight: 700;
  cursor: pointer;
}
