.under-bar {
  background-color: #2f2f2f;
  height: 278px;
  width: 1728px;
}

.under-bar .frame {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 17px;
  left: 38px;
  position: relative;
  top: 39px;
}

.under-bar .div {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24px;
  position: relative;
}

.under-bar .div-20 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.under-bar .frame-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 19px;
  position: relative;
}

.under-bar .span {
  font-weight: 700;
}

.under-bar .text-wrapper-2 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
}

.under-bar .p {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
