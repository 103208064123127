.home {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.home .div-3 {
  background-color: #ffffff;
  height: 1554px;
  position: relative;
  width: 1728px;
}

.home .overlap {
  background-color: #ffd0d047;
  height: 228px;
  left: 314px;
  position: absolute;
  top: 1009px;
  width: 1100px;
}

.home .element-2 {
  color: #000000;
  font-family: "NanumBarunGothic-Regular", Helvetica;
  font-size: 34px;
  font-weight: 400;
  left: 66px;
  letter-spacing: 0;
  line-height: 52.5px;
  position: absolute;
  top: 40px;
}

.home .text-wrapper-5 {
  color: #000000;
  font-family: "NanumBarunGothic-Regular", Helvetica;
  font-size: 34px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 52.5px;
}

.home .text-wrapper-6 {
  font-family: "NanumBarunGothic-Bold", Helvetica;
  font-weight: 700;
}

.home .group {
  height: 166px;
  left: 909px;
  position: absolute;
  top: 31px;
  width: 131px;
  cursor: pointer;
}

.home .text-wrapper-7 {
  color: #000000;
  font-family: "NanumBarunGothic-Regular", Helvetica;
  font-size: 34px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 52.5px;
  position: absolute;
  text-align: center;
  top: 114px;
  white-space: nowrap;
}

.home .group-2 {
  height: 102px;
  left: 33px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 72px;
}

.home .group-3 {
  height: 166px;
  left: 701px;
  position: absolute;
  top: 31px;
  width: 133px;
  cursor: pointer;
}

.home .group-4 {
  height: 101px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 131px;
}

.home .upper-bar {
  background-color: #ffffff;
  height: 138px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1728px;
}

.home .under-bar-instance {
  left: 0 !important;
  position: absolute !important;
  top: 1276px !important;
}

.home .text-wrapper-11 {
  color: #fe0000;
  font-family: "NanumBarunGothic-Regular", Helvetica;
  font-size: 38px;
  font-weight: 400;
  left: 792px;
  letter-spacing: 0;
  line-height: 52.2px;
  position: absolute;
  text-align: center;
  top: 432px;
  white-space: nowrap;
}

.home .text-wrapper-12 {
  color: #000000;
  font-family: "NanumBarunGothic-Regular", Helvetica;
  font-size: 25px;
  font-weight: 400;
  left: 660px;
  letter-spacing: 0;
  line-height: 34.3px;
  position: absolute;
  text-align: center;
  top: 484px;
  white-space: nowrap;
}

.home .text-wrapper-13 {
  color: #fe0000;
  font-family: "NanumBarunGothic-Regular", Helvetica;
  font-size: 38px;
  font-weight: 400;
  left: 792px;
  letter-spacing: 0;
  line-height: 52.2px;
  position: absolute;
  text-align: center;
  top: 906px;
  white-space: nowrap;
}

.home .text-wrapper-14 {
  color: #000000;
  font-family: "NanumBarunGothic-Regular", Helvetica;
  font-size: 25px;
  font-weight: 400;
  left: 593px;
  letter-spacing: 0;
  line-height: 34.3px;
  position: absolute;
  text-align: center;
  top: 958px;
  white-space: nowrap;
}

.home .banner-instance {
  left: 0 !important;
  position: absolute !important;
  top: 138px !important;
}

.home .text-wrapper-15 {
  color: #000000;
  font-family: "NanumBarunGothic-Regular", Helvetica;
  font-size: 23px;
  font-weight: 400;
  left: 1598px;
  letter-spacing: 0;
  line-height: 35.5px;
  position: absolute;
  text-align: center;
  top: 484px;
  white-space: nowrap;
  cursor: pointer;
}

.home .photo-grid-home {
  left: 42px;
  position: absolute;
  top: 563px;
}
