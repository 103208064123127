.upper-bar {
  background-color: #ffffff;
  height: 138px;
  position: relative;
  width: 1728px;
}

.upper-bar .frame {
  align-items: flex-start;
  display: inline-flex;
  gap: 36px;
  left: 1527px;
  position: absolute;
  top: 27px;
}

.upper-bar .div {
  color: #000000;
  font-family: "Inter-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.upper-bar .text-wrapper-2 {
  color: #000000;
  font-family: "Inter-Bold", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.upper-bar .frame-2 {
  align-items: flex-end;
  display: flex;
  gap: 123px;
  left: 27px;
  position: absolute;
  top: 46px;
  width: 1571px;
}

.upper-bar .frame-3 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 28px;
  position: relative;
  cursor: pointer;
}

.upper-bar .logo {
  height: 62px;
  position: relative;
  width: 109px;
}

.upper-bar .flexcontainer {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
}

.upper-bar .text-i {
  align-self: stretch;
  color: #000000;
  font-family: "Inter-Bold", Helvetica;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.upper-bar .span {
  color: #000000;
  font-family: "Inter-Bold", Helvetica;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: 0;
}

.upper-bar .upper-menu-instance {
  flex: 0 0 auto !important;
}

.upper-bar .sub-menu {
  position: absolute;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 100;
  width: 200px;
  margin-top: 0px;
  border: 1px solid #ddd;
  left: 50%; /* 현재 요소의 왼쪽 모서리를 상위 요소의 중앙으로 이동 */
  transform: translateX(-50%);
}

.upper-bar .sub-menu button {
  display: block;
  padding: 10px;
  border: none;
  width: 100%;
  text-align: center;
  cursor: pointer;
  background-color: transparent;
  font-family: "Inter-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
}

.upper-bar .sub-menu button:hover {
  background-color: #f7f7f7;
}