.qna-list {
  background-color: #ffffff;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #838383;
  height: 65px;
  position: relative;
  width: 1486px;
}

.qna-list .text-wrapper {
  color: #000000;
  font-family: "NanumBarunGothic-Regular", Helvetica;
  font-size: 23px;
  font-weight: 400;
  left: 8px;
  letter-spacing: 0;
  line-height: 35.5px;
  position: absolute;
  text-align: center;
  top: 12px;
  white-space: nowrap;
}

.qna-list .div {
  color: #000000;
  font-family: "NanumBarunGothic-Regular", Helvetica;
  font-size: 23px;
  font-weight: 400;
  left: 51px;
  letter-spacing: 0;
  line-height: 35.5px;
  position: absolute;
  text-align: center;
  top: 12px;
  width: 652px;
}

.qna-list .text-wrapper-2 {
  color: #000000;
  font-family: "NanumBarunGothic-Regular", Helvetica;
  font-size: 23px;
  font-weight: 400;
  left: 704px;
  letter-spacing: 0;
  line-height: 35.5px;
  position: absolute;
  text-align: center;
  top: 12px;
  width: 150px;
}

.qna-list .text-wrapper-3 {
  color: #000000;
  font-family: "NanumBarunGothic-Regular", Helvetica;
  font-size: 23px;
  font-weight: 400;
  left: 855px;
  letter-spacing: 0;
  line-height: 35.5px;
  position: absolute;
  text-align: center;
  top: 12px;
  width: 182px;
}

.qna-list .text-wrapper-4 {
  color: #000000;
  font-family: "NanumBarunGothic-Regular", Helvetica;
  font-size: 23px;
  font-weight: 400;
  left: 1038px;
  letter-spacing: 0;
  line-height: 35.5px;
  position: absolute;
  text-align: center;
  top: 12px;
  width: 242px;
}

.qna-list .text-wrapper-5 {
  color: #000000;
  font-family: "NanumBarunGothic-Regular", Helvetica;
  font-size: 23px;
  font-weight: 400;
  left: 1281px;
  letter-spacing: 0;
  line-height: 35.5px;
  position: absolute;
  text-align: center;
  top: 12px;
  width: 194px;
}
