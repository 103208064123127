.work-intro {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.work-intro .div-2 {
  background-color: #ffffff;
  height: 1689px;
  position: relative;
  width: 1728px;
}

.work-intro .under-bar-instance {
  left: 0 !important;
  position: absolute !important;
  top: 1411px !important;
}

.work-intro .text-wrapper-9 {
  color: #fe0000;
  font-family: "NanumBarunGothic-Regular", Helvetica;
  font-size: 38px;
  font-weight: 400;
  left: 792px;
  letter-spacing: 0;
  line-height: 52.2px;
  position: absolute;
  text-align: center;
  top: 432px;
  white-space: nowrap;
}

.work-intro .text-wrapper-10 {
  color: #000000;
  font-family: "NanumBarunGothic-Regular", Helvetica;
  font-size: 25px;
  font-weight: 400;
  left: 662px;
  letter-spacing: 0;
  line-height: 34.3px;
  position: absolute;
  text-align: center;
  top: 484px;
  white-space: nowrap;
}

.work-intro .group {
  background-color: #d9d9d9;
  height: 250px;
  left: 183px;
  position: absolute;
  top: 569px;
  width: 250px;
  background-size: cover;
  background-position: center;
}

.work-intro .group-2 {
  background-color: #d9d9d9;
  height: 250px;
  left: 183px;
  position: absolute;
  top: 845px;
  width: 250px;
  background-size: cover;
  background-position: center;
}

.work-intro .group-3 {
  background-color: #d9d9d9;
  height: 250px;
  left: 183px;
  position: absolute;
  top: 1121px;
  width: 250px;
  background-size: cover;
  background-position: center;
}

.work-intro .banner-instance {
  left: 0 !important;
  position: absolute !important;
  top: 138px !important;
}

.work-intro .element {
  color: transparent;
  font-family: "NanumBarunGothic-Bold", Helvetica;
  font-size: 30px;
  font-weight: 400;
  left: 506px;
  letter-spacing: 0;
  /* line-height: 56.5px; */
  position: absolute;
  top: 602px;
  width: 862px;
}

.work-intro .text-wrapper-11 {
  color: #fe0000;
  font-weight: 700;
}

.work-intro .text-wrapper-12 {
  color: #000000;
  font-family: "NanumBarunGothic-Regular", Helvetica;
  font-size: 23px;
  line-height: 43.3px;
}

.work-intro .element-2 {
  color: transparent;
  font-family: "NanumBarunGothic-Bold", Helvetica;
  font-size: 30px;
  font-weight: 400;
  left: 506px;
  letter-spacing: 0;
  /* line-height: 56.5px; */
  position: absolute;
  top: 878px;
  width: 862px;
}

.work-intro .element-3 {
  color: transparent;
  font-family: "NanumBarunGothic-Bold", Helvetica;
  font-size: 30px;
  font-weight: 400;
  left: 506px;
  letter-spacing: 0;
  /* line-height: 56.5px; */
  position: absolute;
  top: 1154px;
  width: 862px;
}
