.photo {
  height: 300px;
  width: 301px;
}

.photo .overlap-group-2 {
  height: 300px;
  position: relative;
}

.photo .img {
  height: 300px;
  left: 1px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 300px;
}

.photo .rectangle-2 {
  background-color: #08080875;
  height: 105px;
  left: 0;
  position: absolute;
  top: 195px;
  width: 301px;
}

.photo .element {
  color: #ffffff;
  font-family: "NanumBarunGothic-Regular", Helvetica;
  font-size: 100%;
  font-weight: 400;
  left: 9px;
  letter-spacing: 0;
  line-height: 27.5px;
  position: absolute;
  top: 206px;
}
