.work-gallery {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.work-gallery .div-2 {
  background-color: #ffffff;
  height: 1554px;
  position: relative;
  width: 1728px;
}

.work-gallery .upper-bar {
  background-color: #ffffff;
  height: 138px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1728px;
}

.work-gallery .under-bar-instance {
  left: 0 !important;
  position: absolute !important;
  top: 1276px !important;
}

.work-gallery .text-wrapper-9 {
  color: #fe0000;
  font-family: "NanumBarunGothic-Regular", Helvetica;
  font-size: 38px;
  font-weight: 400;
  left: 792px;
  letter-spacing: 0;
  line-height: 52.2px;
  position: absolute;
  text-align: center;
  top: 432px;
  white-space: nowrap;
}

.work-gallery .text-wrapper-10 {
  color: #000000;
  font-family: "NanumBarunGothic-Regular", Helvetica;
  font-size: 25px;
  font-weight: 400;
  left: 660px;
  letter-spacing: 0;
  line-height: 34.3px;
  position: absolute;
  text-align: center;
  top: 484px;
  white-space: nowrap;
}

.work-gallery .frame-6 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 41px;
  left: 39px;
  position: absolute;
  top: 541px;
}

.work-gallery .frame-7 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 35px;
  position: relative;
}

.work-gallery .photo-instance {
  position: relative !important;
}

.work-gallery .banner-instance {
  left: 0 !important;
  position: absolute !important;
  top: 138px !important;
}

.work-gallery .group {
  height: 50px;
  left: 1578px;
  position: absolute;
  top: 469px;
  width: 113px;
}

.work-gallery .overlap {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #000000;
  height: 50px;
  position: relative;
  width: 111px;
}

.work-gallery .text-wrapper-11 {
  color: #000000;
  font-family: "NanumBarunGothic-Regular", Helvetica;
  font-size: 23px;
  font-weight: 400;
  left: 24px;
  letter-spacing: 0;
  line-height: 35.5px;
  position: absolute;
  text-align: center;
  top: 8px;
  white-space: nowrap;
}

.work-gallery .frame-8 {
  align-items: flex-start;
  display: inline-flex;
  left: 618px;
  position: absolute;
  top: 1222px;
}

.work-gallery .text-wrapper-12 {
  color: #000000;
  font-family: "NanumBarunGothic-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 29px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 99px;
}

.work-gallery .text-wrapper-13 {
  color: #000000;
  font-family: "NanumBarunGothic-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 29px;
  letter-spacing: 0;
  line-height: normal;
  margin-left: -1px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 99px;
}

/* 모달 관련 스타일링 */
.work-gallery .modal {
  background-color: rgba(0, 0, 0, 0.7); /* 반투명한 검정 배경 */
  position: relative; /* 고정 위치 */
  top: 0; 
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center; 
  align-items: center; /* 내용을 화면 가운데로 정렬 */
  z-index: 1000; /* 다른 요소들 위에 위치하도록 z-index 설정 */
}

.work-gallery .modal-content {
  background-color: #ffffff;
  padding: 20px;
  width: 20%; /* 뷰포트의 70% 너비 */
  height: 30%;
  top: 15%;
  position: absolute;
  max-width: 600px; /* 최대 너비 설정 */
  border-radius: 10px; /* 모서리 둥글게 */
}

.work-gallery .modal-file {
  position: absolute;
  top: 15%;
  left: 20%;
  height: 5%;
  width: 100%;
}

.work-gallery .modal-img {
  position: absolute;
  top: 22%;
  left: 20%;
  height: 40%;
  width: 60%;
}

.work-gallery .modal-kind {
  position: absolute;
  top: 65%;
  left: 20%;
  height: 5%;
  width: 60%;
}

.work-gallery .modal-place {
  position: absolute;
  top: 72.5%;
  left: 20%;
  height: 5%;
  width: 60%;
}

.work-gallery .modal-duration {
  position: absolute;
  top: 80%;
  left: 20%;
  height: 5%;
  width: 60%;
}

.work-gallery .modal-submit {
  position: absolute;
  top: 90%;
  left: 35%;
  width: 30%;
}

.work-gallery .modal img {
  max-width: 100%; /* 이미지가 모달의 너비를 넘지 않도록 설정 */
  margin-bottom: 20px; /* 이미지 아래에 여백 추가 */
}

.work-gallery .modal input[type="file"] {
  margin-bottom: 10px;
}

.work-gallery .modal-content input[type="text"] {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.work-gallery .modal button {
  background-color: #fe0000;
  color: #ffffff;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.work-gallery .modal button:hover {
  background-color: #d90000;
}
