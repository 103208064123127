.history {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.history .div-2 {
  background-color: #ffffff;
  height: 1373px;
  position: relative;
  width: 1728px;
}

.history .upper-bar {
  background-color: #ffffff;
  height: 138px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1728px;
}

.history .under-bar-instance {
  left: 0 !important;
  position: absolute !important;
  top: 1095px !important;
}

.history .text-wrapper-9 {
  color: #fe0000;
  font-family: "NanumBarunGothic-Regular", Helvetica;
  font-size: 38px;
  font-weight: 400;
  left: 830px;
  letter-spacing: 0;
  line-height: 52.2px;
  position: absolute;
  text-align: center;
  top: 432px;
  white-space: nowrap;
}

.history .banner-instance {
  left: 0 !important;
  position: absolute !important;
  top: 138px !important;
}

.history .flexcontainer-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 464px;
  left: 538px;
  position: absolute;
  top: 552px;
  width: 685px;
}

.history .text {
  align-self: stretch;
  color: transparent;
  font-family: "NanumBarunGothic-Bold", Helvetica;
  font-size: 25px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 34.3px;
  position: relative;
}

.history .text-wrapper-10 {
  color: #fe0000;
  font-weight: 700;
}

.history .text-wrapper-11 {
  color: #000000;
  font-family: "NanumBarunGothic-Regular", Helvetica;
}
