.ceo-greet {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.ceo-greet .div-3 {
  background-color: #ffffff;
  height: 1608px;
  position: relative;
  width: 1728px;
}

.ceo-greet .upper-menu-instance {
  flex: 0 0 auto !important;
}

.ceo-greet .under-bar-instance {
  left: 0 !important;
  position: absolute !important;
  top: 1330px !important;
}

.ceo-greet .text-wrapper-9 {
  color: #fe0000;
  font-family: "NanumBarunGothic-Regular", Helvetica;
  font-size: 38px;
  font-weight: 400;
  left: 741px;
  letter-spacing: 0;
  line-height: 52.2px;
  position: absolute;
  text-align: center;
  top: 432px;
  white-space: nowrap;
}

.ceo-greet .group {
  height: 342px;
  left: 474px;
  position: absolute;
  top: 546px;
  width: 282px;
}

.ceo-greet .text-wrapper-10 {
  color: #000000;
  font-family: "NanumBarunGothic-Regular", Helvetica;
  font-size: 25px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 34.3px;
  position: absolute;
  text-align: center;
  top: 308px;
  white-space: nowrap;
}

.ceo-greet .ellipse {
  background-color: #d9d9d9;
  border-radius: 140px;
  height: 280px;
  left: 0;
  position: absolute;
  top: 0;
  width: 280px;
}

.ceo-greet .text-wrapper-11 {
  color: #000000;
  font-family: "NanumBarunGothic-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  letter-spacing: 0;
  line-height: 33px;
  position: absolute;
  top: 545px;
}

.ceo-greet .group-2 {
  height: 46px;
  left: 692px;
  position: absolute;
  top: 1229px;
  width: 347px;
}

.ceo-greet .text-wrapper-12 {
  color: #000000;
  font-family: "NanumBarunGothic-Regular", Helvetica;
  font-size: 21px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 28.9px;
  position: absolute;
  /* text-align: center; */
  top: 0px;
  white-space: nowrap;
}

.ceo-greet .text-wrapper-13 {
  color: #000000;
  font-family: "NanumBarunGothic-Bold", Helvetica;
  font-size: 25px;
  font-weight: 700;
  left: 254px;
  letter-spacing: 0;
  line-height: 45.3px;
  position: absolute;
  text-align: center;
  top: 0px;
  white-space: nowrap;
}

.ceo-greet .banner-instance {
  left: 0 !important;
  position: absolute !important;
  top: 138px !important;
}
